<template>
    <div>
        <div class="c w-xxl section-margin-top pagebg">
            <div class="flex -justify-center">
                <CenteredText
                    :title="$t('404.title')"
                    :text="$t('404.text')"
                    :link="{
                        title: $t('menu.home'),
                        to: { name: 'home' },
                        class: 'bg-white',
                        icon: 'chevron-right'
                    }"
                />
            </div>
        </div>

    </div>
</template>

<script>
export default {
    name: 'Pricelist',
    components: {
        CenteredText: () => import('@/components/CenteredText.vue'),
    },
};
</script>
